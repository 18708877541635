import {PartialBy} from "../util/model.util";
import {http} from "../util/http.util";

export interface Tour {
    id: string,
    name: string,
    sort: number,
    url: string
}

export type TourCreate = PartialBy<Tour, 'id'>

export const getTours = () => {
    return http.get<Tour[]>('/api/tour');
}

export const createTour = (tour: TourCreate) => {
    return http.post<Location>('/api/tour', tour);
}

export const deleteTour = (id: string) => {
    return http.delete(`/api/tour/${id}`);
}

export const updateTour = (tour: Tour) => {
    const {id, ...update} = tour;
    return http.put(`/api/tour/${id}`, update);
}

export const updateTourSort = (sort: string[]) => {
    return http.post(`/api/tour/sort`, sort);
}
