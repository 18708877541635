import background from "../assets/background.gif";
import logo from "../assets/logo.png";
import {useEffect, useState} from "react";
import {getTours, Tour} from "../services/tour-service";
import {Card, Empty, List} from "antd";
import {getLocations, Location} from "../services/location-service";
import Meta from "antd/es/card/Meta";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

import './arrow.css';
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../components/button/HomeButton";

export const LocationMenu = ({}) => {
    const {id} = useParams();

    const [selectedTour, setSelectedTour] = useState<Tour>()
    const [locations, setLocations] = useState<Location[]>([]);
    const navigate = useNavigate();
    const sizePerPage = 4;
    const pageSize = Math.min(sizePerPage, locations.length);
    const showNavigateButton = pageSize >= sizePerPage;

    useEffect(() => {
        getTours().then((data) => {
            const selectedTour = data.data.filter(x => x.id === id)[0];
            setSelectedTour(selectedTour);
        })
    }, [id]);

    useEffect(() => {
        if (selectedTour) {
            getLocations(selectedTour!.id).then((data) => {
                console.log(data.data);
                setLocations(data.data);
            })
        }
    }, [selectedTour]);

    const leftArrowClicked = () => {

    };
    const rightArrowClicked = () => {

    };
    const leftArrowDisabled = () => {
       return true
    };
    const rightArrowDisabled = () => {
        return true
    };

    return <div style={{
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <video id="background-video" autoPlay loop muted>
            <source src={require('../assets/background.mp4')} type="video/mp4"/>
        </video>
        <img src={logo} alt='logo' style={{width: '20%'}}/>
        <div
            style={{
                width: '50%',
                textAlign: 'center',
                background: 'rgb(237,237,237)',
                margin: 30,
                boxShadow: "7px 6px 12px 0px lightgrey",
                borderRadius: 20
            }}
        >
            <h1 style={{fontSize: 40}}>{selectedTour?.name}</h1>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            {
                showNavigateButton &&
                <LeftOutlined
                    style={{fontSize: '200px', width: '3vw'}}
                    className={`arrow ${leftArrowDisabled() && 'arrow-disabled'}`}
                    onClick={leftArrowClicked}
                />
            }
            <List grid={{gutter: 25, column: Math.min(locations.length, sizePerPage)}}
                  dataSource={locations.slice(0, Math.min(locations.length, sizePerPage))} renderItem={(loc) => {
                return <List.Item><Card
                    onClick={() => {
                        navigate(`/tour?location-id=${loc.id}`)
                    }}
                    style={{
                        width: '250px',
                        borderRadius: 13,
                        boxShadow: '5px 7px 9px lightgrey'
                    }}
                    hoverable={!!loc.url}
                    cover={loc.url ? <div style={{padding: '10px 10px 0 10px'}}>
                        <img
                            alt={loc.name}
                            src={loc.url}
                            style={{
                                objectFit: 'cover',
                                width: '230px',
                                height: '230px',
                            }}
                        />
                    </div> : <div style={{padding: 10}}><Empty imageStyle={{width: '230px', height: '230px'}}
                                                               description={false}/></div>}
                >
                    <Meta style={{textAlign: 'center'}} title={loc.name ?? 'No Data'}/>

                </Card></List.Item>;
            }}/>
            {showNavigateButton &&
                <RightOutlined
                    style={{fontSize: '200px', width: '3vw'}}
                    className={`arrow ${rightArrowDisabled() && 'arrow-disabled'}`}
                    onClick={rightArrowClicked}
                />
            }
        </div>


        <HomeButton style={{left: -7, width: 136, fontSize: 40, padding: '10px 0'}}/>

    </div>
}
