import {http} from "../util/http.util";
import {PartialBy} from "../util/model.util";

export interface Scene {
    id: string,
    name: string,
    url: string,
    locationId: string
}

export type SceneCreate = PartialBy<Scene, 'id'>

export const getScenes = (locationId: string) => {
    return http.get<Scene[]>(`/api/location/${locationId}/scene`);
}

export const getScene = (sceneId: string) => {
    return http.get<Scene>(`/api/scene/${sceneId}`)
}

export const createScene = (scene: SceneCreate) => {
    return http.post<Scene>('/api/scene', scene);
}

export const deleteScene = (id: string) => {
    return http.delete(`/api/scene/${id}`);
}

export const updateScene = (scene: Scene) => {
    const {id, locationId, ...update} = scene;
    return http.put(`/api/scene/${id}`, update);
}