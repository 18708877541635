import {VideoPlayer} from "../components/video-player";
import {Modal} from "antd";

const Test = () => {
    return <>
        <div style={{width: '100vw', height: '100vh'}}>
            <Modal open={true}
                   onOk={() => {}}
                   onCancel={() => {
                   }}
                   width={1000}
                   footer={null}
            >
                <VideoPlayer url={"https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"}/>
            </Modal>
        </div>
    </>
}

export default Test;