import {http} from "../util/http.util";
import {PartialBy} from "../util/model.util";

export interface Location {
    id: string,
    name: string,
    url: string,
    tourId: string
}

export type LocationCreate = PartialBy<Location, 'id'>

export const getLocations = (tourId: string) => {
    return http.get<Location[]>(`/api/tour/${tourId}/location`);
}

export const createLocation = (location: LocationCreate) => {
    return http.post<Location>('/api/location', location);
}

export const deleteLocation = (id: string) => {
    return http.delete(`/api/location/${id}`);
}

export const updateLocation = (location: Location) => {
    const {id, ...update} = location;
    return http.put(`/api/location/${id}`, update);
}