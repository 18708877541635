import {HomeOutlined} from "@ant-design/icons";
import {FloatButton} from "antd";
import {useNavigate} from "react-router-dom";

export const HomeButton = ({style = {left: 30, width: 50, fontSize: '40px'}}: {style?: React.CSSProperties}) => {
    const navigate = useNavigate();
    return <FloatButton
        icon={<HomeOutlined style={{fontSize: '25px', width: '3vw'}}/>}
        description={'home'.toUpperCase()}
        shape="square"
        style={style}
        onClick={() => {
            navigate("/")
        }}
    />
}