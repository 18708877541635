import {Card, ConfigProvider} from "antd";
import Meta from "antd/es/card/Meta";
import TextArea from "antd/lib/input/TextArea";

const PreviewCard = ({
                         scene, active, onClick = (scene) => {
    }
                     }) => {

    const name = `${scene.name}`;
    return (
        <ConfigProvider
            theme={{
                token: {
                    fontSize: '10px'
                },
            }}
        >
            <Card
                hoverable
                style={{width: 120}}
                size='small'
                bordered={true}
                cover={<img alt={scene.id} src={scene.url} style={{height: '60px'}}/>}
                bodyStyle={{padding: '5px'}}
                onClick={() => onClick(scene)}
            >
                <Meta title={<TextArea rows={2} value={`${name.toUpperCase()}`} size={'small'} readOnly style={{
                    padding: '4px 0',
                    whiteSpace: 'pre-line',
                    border: 'none',
                    width: '100%',
                    resize: 'none',
                    outline: 'none',
                    lineHeight: '1.1',
                    cursor: 'default',
                    fontSize: '10px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    fontWeight: '800',
                    paddingTop: '10',
                    color: active? 'red': 'black'
                }}/>}
                      style={{height: '30px', textAlign: 'center', padding: '0'}}/>
            </Card>
        </ConfigProvider>

    )
}

export default PreviewCard;