import React, {useState} from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const MAX_LAYERS = 2;

const pulse = keyframes`
  0%,10% {
    opacity: 0;
    transform: scale(1.1);
  }
  80% {
    opacity: 0.5;
    transform: scale(1.15,1.15);
  }
  81%, 100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const Scale = keyframes`
  0% {
    transform: scale(1);
  }
  35%, 80% {
    transform: scale(1.1,1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Pulse = styled.div`
  animation: ${({ layer }) => (layer ? pulse : Scale)} 1.5s infinite;
  background: #bbb;
  border-radius: 32px;
  height: ${({ layer = 0, height }) => height + layer * 8}px;
  position: absolute;
  width: ${({ layer = 0, width }) => width + layer * 8}px;
  z-index: ${({ layer = 0 }) => MAX_LAYERS - layer};
  opacity: 0.85
  
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px;
  position: relative;
  &:hover ${Pulse} {
    animation: none;
    cursor: pointer;
  }
`;

const Container = styled.div`
  z-index: ${MAX_LAYERS + 1};
`;

const Pulsating = ({ children, visible, onClick=() => {}, ...other }) => {
    return (
        <Wrapper onClick={onClick}>
            <Container>{children}</Container>
            {visible &&
                Array.from(Array(MAX_LAYERS).keys()).map((key) => (
                    <Pulse key={key} layer={key} {...other} />
                ))}
        </Wrapper>
    );
};

Pulsating.propTypes = {
    children: PropTypes.element,
    color: PropTypes.string,
    height: PropTypes.number,
    visible: PropTypes.bool,
    width: PropTypes.number
};

Pulsating.defaultProps = {
    children: null,
    color: "#eee",
    height: 50,
    visible: false,
    width: 50
};

export default Pulsating;
