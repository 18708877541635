export const arrayEquals = (a: any[], b: any[]) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export const arrayTruncateAndFill = (arr: any[], size: number, fillValue: any = null) => {
    if (arr.length >= size) {
        return arr.slice(0, size);
    } else {
        const remainingItems = size - arr.length;
        const fillArray = new Array(remainingItems).fill(fillValue);
        return arr.concat(fillArray);
    }
}