import env from "react-dotenv";

export const MarkerConstant = {
    'Router': {
        url: 'https://storage.googleapis.com/mayair-tour/marker/router.png',
        sceneUrl: 'https://storage.googleapis.com/mayair-tour/marker/router-new2.png',
    },
    'Catalogue': {
        url: 'https://storage.googleapis.com/mayair-tour/marker/document.jpeg',
        sceneUrl: 'https://storage.googleapis.com/mayair-tour/marker/panel.png',
    },
    'Video': {
        url: 'https://storage.googleapis.com/mayair-tour/marker/video.png',
        sceneUrl: 'https://storage.googleapis.com/mayair-tour/marker/video-play.png',
    }
}
