import React, {useState} from "react";
import {Button} from "antd";
import {Location} from "../../../services/location-service";
import {ImageCard} from "../../../components/image-card";
import ListSort from "../../../components/list-sort";
import {arrayEquals} from "../../../util/array.util";

const LocationMenu = (
    {
        locations,
        locationSelected = (_) => {},
        locationEdited = (_) => {},
        locationDeleted = (_) => {},
        locationAdded = () => {},
        locationSorted = (_) => {}
    }
        :
    { locations: Location[], locationSelected?: (location: Location) => void, locationEdited?: (location: Location) => void, locationDeleted?: (location: Location) => void, locationAdded?: () => void, locationSorted?: (x: string[]) => void}
) => {
    const [sortState, setSortState] = useState('normal')
    const [sort, setSort] = useState(locations.map(x => x.id));
    const cardDisable = sortState === 'sort';

    const createCard = () => {
        return locations.map(location =>
            <ImageCard key={location.id} name={location.name} url={location.url}
                       onSelect={() => {
                           if (cardDisable) return;
                           locationSelected(location);
                       }}
                       onEdit={() => {
                           if (cardDisable) return;
                           locationEdited(location);
                       }}
                       onDelete={() => {
                           if (cardDisable) return;
                           locationDeleted(location);
                       }}
            />)
    }

    const handleSort = () => {
        const map = locations.map(x => x.id);
        if (!arrayEquals(map, sort)) {
            console.log(map);
            console.log(sort);
            console.log('sorted');
            locationSorted(sort);
        }
    };

    function switchSortState() {
        if (sortState === 'normal') {
            setSortState('sort');
        } else {
            handleSort();
            setSortState('normal');
        }
    }


    return (
        <div id='scrollableDiv' style={{display: 'flex', flexDirection: 'column', padding: '15px 15px', height: '90vh', overflowY: 'auto'}}>
            <div>
                <ListSort disabled={!cardDisable} onChange={(sorted: any[]) => {
                    const list = sorted.map(tour => tour.key);
                    setSort(list)
                }}>
                    {createCard()}
                </ListSort>
            </div>

            <Button onClick={() => {
                switchSortState();
            }}>
                {
                    sortState === 'sort' ? 'Confirm' : 'Sort'
                }
            </Button>
            <Button onClick={() => {
                if (cardDisable) return;
                locationAdded();
            }}>
                Add new location
            </Button>
        </div>
    );

}

export default LocationMenu;