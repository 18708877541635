import {useFrame, useThree} from "react-three-fiber";
import React, {forwardRef, useEffect, useState} from "react";
import {useSpring} from "react-spring";
import {Vector2, Vector3} from "three";

const CameraAnimation = forwardRef(({
                                        orbitControls,
                                        sceneRef,
                                        defaultRotate,
                                        backToHorizon,
                                        selectPosition,
                                        onPositionSelected,
                                        resetPosition,
                                        ...props
                                    }, ref) => {
    React.useImperativeHandle(ref, () => ({
        moveCameraTo,
        setMovingState,
    }));
    const defaultState = defaultRotate ? 'rotate' : 'control';
    const {camera, gl, raycaster, size} = useThree()
    const [movingState, setMovingState] = useState(defaultState);
    const [timeoutId, setTimeoutId] = useState()
    const [rotate, rotateApi] = useSpring(() => {});
    const [moving, movingApi] = useSpring(() => {});

    useEffect(() => {
            console.log(movingState);
            if (movingState === 'rotate') {
                orbitControls.current.autoRotate = true;
            } else if (movingState === 'backToHorizon') {
                backToOriginalPosition();
            } else if (movingState === 'goingBackToHorizon') {
                handleBackToHorizon();
            } else if (movingState === 'control') {
                rotateApi.stop(true);
                orbitControls.current.autoRotate = false;
            } else if (movingState === 'hold') {
                clearTimeout(timeoutId);
                setMovingState('control')
            } else if (movingState === 'moveTo') {
                // moveCameraTo(movingTo);
            }

        },
        [movingState]
    )

    const moveCameraTo = (destination) => {
        destination = destination.map(x => x/8);
        // const polarAngle = orbitControls.current.getPolarAngle();
        // const ori = Math.PI / 2;
        // const duration = (2000 * (Math.abs((polarAngle - ori)) / ori)) + 2000
        movingApi.start({
            from: {
                x: camera.position.x,
                y: camera.position.y,
                z: camera.position.z
            },
            to: {
                x: destination[0],
                y: destination[1],
                z: destination[2]
            },
            config: {
                duration: 3000
            },
            onStart: () => {
                clearTimeout(timeoutId);
                setMovingState('control');
                orbitControls.current.target = new Vector3(destination[0], destination[1], destination[2])
            },
            onChange: ({value}) => {
                const {x,y,z} = value;
                camera.position.copy(new Vector3(x,y,z))
                camera.updateMatrixWorld();
            },
            onRest: (result, ctrl, item) => {
                orbitControls.current.target = new Vector3(0, 0, 0)
                camera.position.copy(new Vector3(resetPosition[0], resetPosition[1], resetPosition[2]));
                camera.updateMatrixWorld();
                if (result.finished) {
                    setTimeout(() => setMovingState(defaultState), 1500)
                }
            }
        });
    }

    const backToOriginalPosition = () => {
        const polarAngle = orbitControls.current.getPolarAngle();
        const ori = Math.PI / 2;
        const duration = (2000 * (Math.abs((polarAngle - ori)) / ori)) + 2000
        rotateApi.start({
            from: {
                position: camera.position.y
            },
            to: {
                position: 0
            },
            config: {
                duration: duration
            },
            onStart: () => {
                orbitControls.current.autoRotate = false;
                // orbitControls.current.enabled = false;
            },
            onChange: ({value}) => {
                const {position} = value;
                camera.position.copy(new Vector3(camera.position.x, position, camera.position.z))
                camera.updateMatrixWorld();
            },
            onRest: (result, ctrl, item) => {
                if (result.finished) {
                    setMovingState(defaultState);
                }
            }
        });
    }

    const stopRotate = () => {
        setMovingState('control');
    };

    const handleBackToHorizon = (e, listener) => {
        if (backToHorizon) {
            const id = setTimeout(() => {
                setMovingState('backToHorizon')
            }, 3000);
            console.log(id)

            setTimeoutId(id);
        }
    };

    useEffect(() => {
        gl.domElement.addEventListener('mousedown', stopRotate, false);

        return () => {
            gl.domElement.removeEventListener('mousedown', stopRotate, false);
        }
    }, []);

    useEffect(() => {
        const clear = () => {
            clearTimeout(timeoutId);
        }
        gl.domElement.addEventListener('mousedown', clear, false);

        return () => {
            gl.domElement.removeEventListener('mousedown', clear, false);
        }
    }, [timeoutId]);

    useEffect(() => {


        gl.domElement.addEventListener('mouseup', handleBackToHorizon, false);

        const handleGetMarkerPosition = (e, listener) => {
            if (selectPosition) {
                const mouse = new Vector2();
                mouse.x = ((e.clientX - size.left) / size.width) * 2 - 1;
                mouse.y = -((e.clientY - size.top) / size.height) * 2 + 1;
                // Update the picking ray with the camera and mouse position
                raycaster.setFromCamera(mouse, camera);

                // Calculate objects intersecting the picking ray
                const intersects = raycaster.intersectObjects([sceneRef.current]);

                if (intersects.length > 0) {
                    // The first intersection point is where the click occurred
                    const intersectionPoint = intersects[0].point;
                    console.log('Click at 3D Coordinates:', intersectionPoint);
                    onPositionSelected([intersectionPoint.x, intersectionPoint.y, intersectionPoint.z]);
                }
            }
        };
        gl.domElement.addEventListener('click', handleGetMarkerPosition)

        return () => {
            gl.domElement.removeEventListener('mouseup', handleBackToHorizon, false);
            gl.domElement.removeEventListener('click', handleGetMarkerPosition)
        }
    }, [selectPosition, backToHorizon])


    // Update the camera's position during the animation

    useFrame(() => {
        orbitControls.current.update();
    });


    return <orbitControls ref={orbitControls} target={[0, 0, 0]} {...props} args={[camera, gl.domElement]}/>
});

export default CameraAnimation;