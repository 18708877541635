import {http} from "../util/http.util";

export interface UploadFile {
    mimeType: string,
    type: string
}

export const getSignedUrl = (type: string, extension: string, contentType: string) => {
    return http.get<{url: string}>("/api/file/signed-url", {
        params: {
            type,
            extension,
            'content-type': contentType
        }
    })
}


export const getUploadUrl = (uploadFile: UploadFile) => {
    return http.post<UploadFile>("/api/file/upload/url", uploadFile);
}


export const uploadfile = async (file: File, url: string) => {
    const data = await file.arrayBuffer();
    return http.put(url, data, {
        headers: {
            "Content-Type": file.type,
            'Access-Control-Allow-Origin': '*',
        }
    });
}