import React, {useEffect} from "react";
import {ImageCard} from "../../../components/image-card";
import {Button} from "antd";
import {Scene} from "../../../services/scene-service";

const SceneMenu = ({
                       scenes,
                       sceneSelected = (_) => {},
                       sceneEdited = (_) => {},
                       sceneDeleted = (_) => {},
                       sceneAdded = () => {}
                   }
                       :
                       { scenes: Scene[], sceneSelected?: (scene: Scene) => void, sceneEdited?: (scene: Scene) => void, sceneDeleted?: (scene: Scene) => void, sceneAdded?: () => void,}
) => {
    const createCard = () => {
        return scenes.map(scene =>
            <ImageCard key={scene.id} name={scene.name} url={scene.url}
                       onSelect={() => {
                           console.log(scene.name + ' selected');
                           console.log(`id = ${scene.id}`)
                           sceneSelected(scene);
                       }}
                       onEdit={() => {
                           console.log(scene.name + ' edited');
                           sceneEdited(scene);
                       }}
                       onDelete={() => {
                           console.log(scene.name + ' deleted');
                           sceneDeleted(scene);
                       }}
            />)
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: '15px 15px', height: '90vh', overflowY: 'auto'}}>
            <div>
                {createCard()}
            </div>
            <Button onClick={() => {
                console.log('add scene');
                sceneAdded();
            }}>
                Add new scene
            </Button>
        </div>
    );
}

export default SceneMenu;