import React, {useEffect, useState} from "react";
import {Breadcrumb, ConfigProvider, Layout, Menu, MenuProps, Modal, theme} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import LocationMenu from "./menu/location-menu";
import SceneMenu from "./menu/scene-menu";
import MarkerMenu from "./menu/marker-menu";
import {
    createLocation,
    deleteLocation,
    getLocations,
    Location,
    LocationCreate,
    updateLocation
} from "../../services/location-service";
import LocationModal from "./modal/location-modal";
import {createScene, deleteScene, getScenes, Scene, SceneCreate, updateScene} from "../../services/scene-service";
import SceneModal from "./modal/scene-modal";
import LocationContent from "./content/location-content";
import SceneContent from "./content/scene-content";
import {
    createMarker,
    deleteMarker,
    getMarkers,
    Marker,
    MarkerCreate,
    updateMarker
} from "../../services/marker-service";
import MarkerModal from "./modal/marker-modal";
import TourMenu from "./menu/tour-menu";
import {
    createTour,
    deleteTour,
    getTours,
    Tour,
    TourCreate,
    updateTour,
    updateTourSort
} from "../../services/tour-service";
import TourModal from "./modal/tour-modal.";
import {validateEditor} from "../../services/security-service";
import {useEditorTokenAuth} from "../../hooks/editor-token-hook";

const {Header, Content, Sider} = Layout;


const Editor = () => {
    useEditorTokenAuth();
    const [selectedMenu, setSelectedMenu] = useState<string>('tour')
    const [tours, setTours] = useState<Tour[]>([]);
    const [selectedTour, setSelectedTour] = useState<Tour>()
    const [locations, setLocations] = useState<Location[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [scenes, setScenes] = useState<Scene[]>([]);
    const [selectedScene, setSelectedScene] = useState<Scene>();
    const [markers, setMarkers] = useState<Marker[]>([]);
    const [selectedMarker, setSelectedMarker] = useState<Marker>()
    const [addTour, setAddTour] = useState(false);
    const [addLocation, setAddLocation] = useState(false);
    const [addScene, setAddScene] = useState(false);
    const [addMarker, setAddMarker] = useState(false);
    const [markerPosition, setMarkerPosition] = useState<[number, number, number]>();
    const [positionSelect, setPositionSelect] = useState(false);
    const [markerModalResumeFunc, setMarkerModalResumeFunc] = useState(() => () => {});
    const [editTour, setEditTour] = useState<Tour>();
    const [editLocation, setEditLocation] = useState<Location>();
    const [editScene, setEditScene] = useState<Scene>();
    const [editMarker, setEditMarker] = useState<Marker>();


    const [confirmDeleteModal, deleteLocationModalContextHolder] = Modal.useModal();
    useEffect(() => {
        console.log(markerModalResumeFunc);
    }, [markerModalResumeFunc]);

    const loadTours = (cb = () => {}) => {
        getTours()
            .then(data => {
                setTours(data.data);
                cb();
            })
    }

    const checkLogin = () => {
        validateEditor().then((data) => {
            if (!data.data.success) {
                window.location.assign(`${data.data.url}`)
            }
        })
    }

    useEffect(() => {
        checkLogin();
        loadTours();
    }, []);

    const loadLocations = (tourId: string, cb = () => {
    }) => {
        getLocations(tourId)
            .then(data => {
                setLocations(data.data);
                cb();
            });
    }

    useEffect(() => {
        if (selectedTour) {
            loadLocations(selectedTour.id);
        }
    }, [selectedTour]);

    const loadScenes = (locationId: string, cb = () => {
    }) => {
        getScenes(locationId)
            .then(data => {
                setScenes(data.data);
                cb();
            })
    }

    useEffect(() => {
        if (selectedLocation) {
            loadScenes(selectedLocation.id);
        }
    }, [selectedLocation]);

    const loadMarkers = (sceneId: string, cb = () => {}) => {
        getMarkers(sceneId)
            .then(data => {
                setMarkers(data.data);
                cb();
            })
    }

    useEffect(() => {
        if (selectedScene) {
            loadMarkers(selectedScene.id)
        }
    }, [selectedScene]);


    const isHeaderDisabled = (key: string): boolean => {
        switch (key) {
            case 'tour':
                return false;
            case 'location':
                return !selectedTour;
            case 'scene':
                return !selectedLocation;
            case 'marker':
                return !selectedScene;
        }

        return true;
    }
    const navItem: MenuProps['items'] = ['tour', 'location', 'scene', 'marker'].map((key) => ({
        key,
        label: key,
        disabled: isHeaderDisabled(key)
    }));

    const {
        token: {colorBgContainer},
    } = theme.useToken();


    const confirmDeleteTour = (tour: Tour) => {
        return confirmDeleteModal.confirm({
            title: 'Confirm Delete Tour',
            icon: <ExclamationCircleOutlined/>,
            content: `Are you sure you want to delete tour ${tour.name}?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                handleDeleteTour(tour);
            },
        })
    }

    const confirmDeleteLocation = (location: Location) => {
        return confirmDeleteModal.confirm({
            title: 'Confirm Delete Location',
            icon: <ExclamationCircleOutlined/>,
            content: `Are you sure you want to delete location ${location.name}?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                handleDeleteLocation(location)
            },
        })
    }

    const confirmDeleteScene = (scene: Scene) => {
        return confirmDeleteModal.confirm({
            title: 'Confirm Delete Scene',
            icon: <ExclamationCircleOutlined/>,
            content: `Are you sure you want to delete scene ${scene.name}?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                handleDeleteScene(scene)
            },
        })
    }

    const confirmDeleteMarker = (marker: Marker) => {
        return confirmDeleteModal.confirm({
            title: 'Confirm Delete Marker',
            icon: <ExclamationCircleOutlined/>,
            content: `Are you sure you want to delete marker ${marker.name}?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                handleDeleteMarker(marker)
            },
        })
    }



    const switchMenu = () => {
        switch (selectedMenu) {
            case 'tour':
                return <TourMenu tours={tours}
                                 tourSelected={(tour) => {
                                     setSelectedTour(tour);
                                     setSelectedMenu('location');
                                     setSelectedLocation(undefined);
                                 }}
                                 tourAdded={() => {
                                     setAddTour(true);
                                 }}
                                 tourEdited={(tour) => {
                                     setEditTour(tour);
                                 }}
                                 tourDeleted={(tour) => {
                                     confirmDeleteTour(tour);
                                 }}
                                 tourSorted={(sort) => {
                                     updateTourSort(sort).then();
                                 }}
                />
            case 'location':
                return <LocationMenu locations={locations}
                                     locationSelected={(location) => {
                                         setSelectedLocation(location);
                                         setSelectedScene(undefined);
                                         setSelectedMenu('scene');
                                     }}
                                     locationAdded={() => {
                                         setAddLocation(true);
                                     }}
                                     locationEdited={(location) => {
                                         setEditLocation(location);
                                     }}
                                     locationDeleted={(location) => {
                                         confirmDeleteLocation(location);
                                     }}
                />
            case 'scene':
                return <SceneMenu scenes={scenes}
                                  sceneSelected={(scene) => {
                                      setSelectedScene(scene);
                                      setSelectedMenu('marker')
                                  }}
                                  sceneAdded={() => {
                                      setAddScene(true);
                                  }}
                                  sceneDeleted={(scene) => {
                                      confirmDeleteScene(scene);
                                  }}
                                  sceneEdited={(scene) => {
                                      setEditScene(scene);
                                  }}
                />
            case 'marker':
                return <MarkerMenu markers={markers}
                                   markerAdded={() => {
                                       setAddMarker(true);
                                   }}
                                   markerDeleted={(marker) => {
                                       confirmDeleteMarker(marker);
                                   }}
                                   markerEdited={(marker) => {
                                       setMarkerPosition(marker.data.position);
                                       setEditMarker(marker);

                                   }}
                                   markerSelected={(marker) => {
                                       setSelectedMarker(marker);
                                   }}
                />
        }
    }

    const switchContent = () => {
        if (selectedLocation && selectedScene) {
            const img = selectedScene.url;
            // @ts-ignore
            return <SceneContent url={img}
                                 markers={markers}
                                 defaultRotate={false}
                                 selectPosition={positionSelect}
                                 backToHorizon={false}
                                 onPositionSelected={(position: [number, number, number]) => {
                                     setPositionSelect(false);
                                     setMarkerPosition(position);
                                     markerModalResumeFunc();
                                     setMarkerModalResumeFunc(() => () => {});
                                 }}

            />
        }


        if (selectedLocation)
            return <LocationContent location={selectedLocation}/>

        return <></>
    }

    const handleAddTour = (tour: TourCreate) => {
        createTour(tour).then((data) => {
            setAddTour(false);
            loadTours();
        });
    }

    const handleDeleteTour = (tour: Tour) => {
        deleteTour(tour.id).then((data) => {
            loadTours();
        })
    }

    const handleEditTour = (tour: Tour) => {
        updateTour(tour).then((data) => {
            loadTours();
            setEditTour(undefined);
        })
    }

    const handleAddLocation = (location: LocationCreate) => {
        location.tourId = selectedTour?.id!;
        createLocation(location).then((data) => {
                setAddLocation(false);
                loadLocations(selectedTour?.id!);
            }
        )
    }

    const handleDeleteLocation = (location: Location) => {
        deleteLocation(location.id).then((data) => {
            loadLocations(selectedTour?.id!);
        })
    }

    const handleEditLocation = (location: Location) => {
        updateLocation(location).then((data) => {
            loadLocations(selectedTour?.id!);
            setEditLocation(undefined);
        })
    }

    const handleAddScene = (scene: SceneCreate) => {
        scene.locationId = selectedLocation?.id!;
        createScene(scene).then((data) => {
                setAddScene(false);
                loadScenes(selectedLocation?.id!);
            }
        )
    }

    const handleDeleteScene = (scene: Scene) => {
        deleteScene(scene.id).then((data) => {
            loadScenes(selectedLocation?.id!);
        })
    }

    const handleEditScene = (scene: Scene) => {
        updateScene(scene).then((data) => {
            loadScenes(selectedLocation?.id!);
            setEditScene(undefined);
        })
    }

    const handleAddMarker = (marker: MarkerCreate) => {
        marker.sceneId = selectedScene?.id!;
        createMarker(marker).then((data) => {
                setAddMarker(false);
                loadMarkers(selectedScene?.id!);
            }
        )
    }

    const handleDeleteMarker = (marker: Marker) => {
        deleteMarker(marker.id).then((data) => {
            loadMarkers(selectedScene?.id!);
        })
    }

    const handleEditMarker = (marker: Marker) => {
        updateMarker(marker).then((data) => {
            loadMarkers(selectedScene?.id!);
            setEditMarker(undefined);
        })
    }

    const switchBreadcrumb = () => {
        if (selectedScene)
            return <Breadcrumb style={{margin: '16px 0'}} items={[{
                title: selectedTour?.name!
            },{
                title: selectedLocation?.name!,
            }, {title: selectedScene.name}]}
            />

        if (selectedLocation)
            return <Breadcrumb style={{margin: '16px 0'}} items={[{
                title: selectedTour?.name!
            },{
                title: selectedLocation.name
            },
            ]}
            />;

        if (selectedTour) {
            return <Breadcrumb style={{margin: '16px 0'}} items={[{
                title: selectedTour.name
            }
            ]}
            />;
        }

        return <Breadcrumb style={{margin: '16px 0'}} items={[]}/>
    }

    return (
        <ConfigProvider theme={{
            components: {
                Layout: {
                    headerPadding: '0 0'
                }
            }
        }}>
            <Layout style={{height: '100vh', width: '100vw'}}>
                <Layout>
                    <Sider width='300px' style={{background: colorBgContainer}}>
                        <Header style={{display: 'flex', alignItems: 'center', width:'300'}}>
                            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[selectedMenu]} items={navItem}
                                  selectedKeys={[selectedMenu]}
                                  onSelect={info => {
                                      setSelectedMenu(info.key)
                                  }}/>
                        </Header>
                        {switchMenu()}
                    </Sider>
                    <Layout style={{padding: '0 24px 24px'}}>
                        {switchBreadcrumb()}
                        <Content
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                                background: colorBgContainer,
                            }}
                        >
                            {switchContent()}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>


            <TourModal open={addTour}
                       tour={undefined}
                       onCreate={(tour) => {
                           handleAddTour(tour);
                       }}
                       onCancel={() => {
                           setAddTour(false);
                       }}
            />
            <TourModal open={!!editTour}
                       tour={editTour}
                       onCreate={(tour) => {
                           handleEditTour({
                               id: editTour!.id!,
                               ...tour
                           });
                       }}
                       onCancel={() => {
                           setEditTour(undefined);
                       }}

            />
            <LocationModal open={addLocation}
                           tourId={selectedTour?.id!}
                           onCreate={(location) => {
                               handleAddLocation(location)
                           }}
                           onCancel={() => {
                               setAddLocation(false)
                           }}
            />
            <LocationModal open={!!editLocation}
                           tourId={selectedTour?.id!}
                           location={editLocation}
                           onCreate={(location) => {
                               handleEditLocation({
                                   id: editLocation!.id!,
                                   ...location
                               });
                           }}
                           onCancel={() => {
                               setEditLocation(undefined)
                           }}/>

            {deleteLocationModalContextHolder}

            <SceneModal open={addScene}
                        onCreate={(scene) => {
                            handleAddScene(scene)
                        }}
                        onCancel={() => {
                            setAddScene(false)
                        }}
            />
            <SceneModal open={!!editScene}
                        scene={editScene}
                        locationId={selectedLocation?.id}
                        onCreate={(location) => {
                            handleEditScene({
                                id: editScene!.id!,
                                ...location
                            });
                        }}
                        onCancel={() => {
                            setEditScene(undefined)
                        }}/>

            <MarkerModal open={addMarker}
                         scenes={scenes}
                         selectedScene={selectedScene}
                         markerPosition={markerPosition}
                         onCreate={(marker) => {
                             handleAddMarker(marker);
                         }}
                         onCancel={() => {
                             setAddMarker(false);
                         }}
                         onSelectPosition={() => {
                             setAddMarker(false);
                             setPositionSelect(true);
                             setMarkerModalResumeFunc(() => () => setAddMarker(true))
                         }}
            />
            <MarkerModal open={!!editMarker}
                         marker={editMarker}
                         scenes={scenes}
                         selectedScene={selectedScene}
                         markerPosition={markerPosition}
                         onCreate={(marker) => {
                             handleEditMarker( {
                                     id: editMarker!.id!,
                                     ...marker,
                             }
                             );
                         }}
                         onCancel={() => {
                             setEditMarker(undefined);
                         }}
                         onSelectPosition={() => {
                             setEditMarker(undefined);
                             setPositionSelect(true);
                             setMarkerModalResumeFunc(() => () => setEditMarker(editMarker))
                         }}
            />

        </ConfigProvider>

    )
}
export default Editor;
