import {Button, Form, FormInstance, Input, Modal, Segmented, Select, Upload} from "antd";
import {Marker, MarkerCreate} from "../../../services/marker-service";
import React, {useEffect, useState} from "react";
import {Scene} from "../../../services/scene-service";
import {DefaultOptionType} from "antd/es/select";
import {InboxOutlined, UploadOutlined} from "@ant-design/icons";
import env from "react-dotenv";
import TextArea from "antd/lib/input/TextArea";
import StorageDragger from "../../../components/upload/storage-dragger";
import StorageUpload from "../../../components/upload/storage-upload";

const MarkerModal = (
    {
        open,
        marker,
        scenes,
        selectedScene,
        markerPosition,
        onCreate,
        onCancel,
        onSelectPosition,
    }
        :
        {
            open: boolean,
            marker?: Marker,
            scenes: Scene[],
            selectedScene: Scene | undefined,
            markerPosition: [number, number, number] | undefined,
            onCreate: (marker: MarkerCreate) => void,
            onCancel: () => void,
            onSelectPosition: () => void,
        }
) => {
    const formRef = React.useRef<FormInstance>(null);
    const [type, setType] = useState<string>('Router');
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const [catalogueDocument, setCatalogueDocument] = useState<string>()
    const [catalogueImage, setCatalogueImage] = useState()
    const [catalogueVideo, setCatalogueVideo] = useState()
    const [video, setVideo] = useState<string>()

    useEffect(() => {
        const opt: DefaultOptionType[] = scenes
            .filter((value) => value.id !== selectedScene?.id)
            .map((value) => ({label: value.name, value: value.id}));

        setOptions(opt);
    }, [scenes, selectedScene]);

    useEffect(() => {
        setCatalogueDocument(undefined);
        setCatalogueImage(undefined);
        setCatalogueVideo(undefined);
        setVideo(undefined);
        formRef.current?.setFieldValue('name', marker?.name);
        if (marker?.data.type === 'Router') {
            setType('Router')
            formRef.current?.setFieldValue('target', marker.data.target)
        }
        if (marker?.data.type === 'Catalogue') {
            setType('Catalogue')
            formRef.current?.setFieldValue('description', marker.data.description);
            setCatalogueDocument(marker.data.url);
            setCatalogueImage(marker.data.imageUrl);
            setCatalogueVideo(marker.data.videoUrl);
        }
        if (marker?.data.type === 'Video') {
            setType('Video')
            setVideo((marker.data.videoUrl))
            // formRef.current?.setFieldValue('display', marker.data.display)
        }
    }, [marker, formRef])


    const switchUploadContent = () => {
        return (catalogueImage) ?
            <img alt={'img'} src={catalogueImage} style={{objectFit: 'contain', width: '100%'}}/>
            :
            <>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

            </>
            ;
    };

    const switchType = () => {
        if (type === 'Router') {
            return (
                <>
                    <Form.Item name='target' label='Target' rules={[{required: true}]}>
                        <Select
                            defaultValue={undefined}
                            options={options}
                        />
                    </Form.Item>
                </>
            );
        }

        if (type === 'Video') {
            return (
                <>
                    <p>Video:</p>
                    <StorageUpload
                        type={'video'}
                        onFileUploaded={(f) => {setVideo(f)}}
                    >
                        <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                    </StorageUpload>
                </>
            )
        }

        return <>
            <Form.Item name='description' label='Description' rules={[{required: true}]}>
                <TextArea rows={6}/>
            </Form.Item>
            <p>Icon:</p>
            <StorageDragger
                type={'catalogue-image'}
                onFileUploaded={(file) => {
                    setCatalogueImage(file);
                }}
            >
                {switchUploadContent()}
            </StorageDragger>
            <p>Document:</p>
            <StorageUpload
                type={'document'}
                onFileUploaded={(f) => {setCatalogueDocument(f)}}
            >
                <Button icon={<UploadOutlined/>}>Click to Upload</Button>
            </StorageUpload>
            <p>Video:</p>
            <StorageUpload
                type={'catalogue-video'}
                onFileUploaded={(f) => {setCatalogueVideo(f)}}
            >
                <Button icon={<UploadOutlined/>}>Click to Upload</Button>
            </StorageUpload>

        </>
    }

    function getData() {
        if (type === 'Router') {
            return {
                type,
                position: markerPosition,
                target: formRef.current?.getFieldValue('target'),
            }
        }

        if (type === 'Video') {
            return {
                type,
                position: markerPosition,
                videoUrl: video
            }
        }

        return {
            type,
            description: formRef.current?.getFieldValue('description'),
            position: markerPosition,
            url: catalogueDocument,
            imageUrl: catalogueImage,
            videoUrl: catalogueVideo
        }
    }

    return (
        <>
            <Modal open={open}
                   onOk={() => {
                   }}
                   onCancel={() => {
                       onCancel();
                   }}
                   footer={[
                       <Button key="selectPosition" onClick={onSelectPosition}>
                           Select Location
                       </Button>,
                       <Button key="back" onClick={onCancel}>
                           Cancel
                       </Button>,
                       <Button key="submit" type="primary" onClick={() => {
                           onCreate({
                               name: formRef.current?.getFieldValue('name'),
                               sceneId: selectedScene?.id ?? '',
                               data: getData()
                           })
                       }}>
                           Ok
                       </Button>,
                   ]}
            >

                <p>Type</p>
                <Segmented title={'Type'} options={['Router', 'Catalogue', 'Video']}
                           onChange={(data) => {
                               setType(data.toString())
                           }}
                />
                <Form ref={formRef} style={{padding: '20px 0'}}>
                    <Form.Item name='name' label='Name' rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='position' label='Position' rules={[{required: true}]}>
                        <span>
                            {markerPosition ? `[${markerPosition[0]}, ${markerPosition[1]}, ${markerPosition[2]}]` : '[]'}
                        </span>
                    </Form.Item>
                    {switchType()}
                </Form>
            </Modal>

        </>
    )
}


export default MarkerModal;