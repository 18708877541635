import {Card} from "antd";
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import Meta from "antd/es/card/Meta";
import {CSSProperties} from "react";

export const ImageCard = ({
                              name, url,
                              onSelect = () => {},
                              onEdit = () => {},
                              onDelete = () => {},
                              imageStyle = {},
                          }: { name: string, url: string, onSelect?: () => void, onEdit?: () => void, onDelete?: () => void , imageStyle?: CSSProperties}) => {
    return (
        <div style={{padding: '10px 0'}}>
            <Card
                style={{width: "100%"}}
                cover={<img alt={name} src={url} onClick={onSelect} style={imageStyle}/>}
                hoverable={true}

                actions={[
                    <EditOutlined key='edit' onClick={onEdit}/>,
                    <DeleteOutlined key='delete' onClick={onDelete}/>,
                ]}
            >
                <Meta
                    title={name}
                />

            </Card>
        </div>
    )
}