import ReactPlayer from "react-player";
import {useRef} from "react";

export const VideoPlayer = ({url}) => {
    const player = useRef();

    return <>
        <ReactPlayer ref={player} controls={true} url={url} width={'100%'}
                     height={'100%'} onProgress={(p) => {
        }} onEnded={() => {
        }}/>
    </>;
}