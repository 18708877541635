import {useCookies} from "react-cookie";

export const useEditorTokenAuth = () => {
    const [cookies, setCookie] = useCookies(['tour'])
    const queryParameters = new URLSearchParams(window.location.search)
    let token= queryParameters.get("editor-token");

    if (token) {
        setCookie('x-mayair-tour-editor-token', token);
    }
}