import axios from "axios";
import env from "react-dotenv";
import {useCookies} from "react-cookie";
import {getCookie} from "./cookie.util";


export const http = axios.create({
    baseURL: env.baseUrl,
    timeout: 10000,
});

http.interceptors.request.use(function (config) {
    const cookie = getCookie('x-mayair-tour-token');
    if (cookie) {
        config.headers['x-mayair-tour-token'] = cookie;
    }
    return config;
});

http.interceptors.request.use(function (config) {
    const cookie = getCookie('x-mayair-tour-editor-token');
    if (cookie) {
        config.headers['x-mayair-tour-editor-token'] = cookie;
    }
    return config;
});
