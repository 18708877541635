import {http} from "../util/http.util";
import {PartialBy} from "../util/model.util";

export interface Marker {
    id: string,
    name: string,
    sceneId: string,
    data: any
}


export type MarkerCreate = PartialBy<Marker, 'id'>


export const getMarkers = (sceneId: string) => {
    return http.get<Marker[]>(`/api/scene/${sceneId}/markers`);
}

export const createMarker = (marker: MarkerCreate) => {
    return http.post<Marker>('/api/marker', marker);
}

export const deleteMarker = (id: string) => {
    console.log(`delete marker ${id}`)
    return http.delete(`/api/marker/${id}`);
}

export const updateMarker = (marker: Marker) => {
    const {id, sceneId, ...update} = marker;
    return http.put(`/api/marker/${id}`, update);
}