import React, {useEffect, useRef, useState} from "react";
import {getUploadUrl, uploadfile} from "../../services/file-service";
import Resizer from "react-image-file-resizer";
import {Spin} from "antd";


const StorageDragger = (
    {
        type,
        children,
        resize  = {required: false, maxWidth: 0, maxHeight: 0, quality: 0},
        onFileUploaded = (url) => {},
        ...props
    }
) => {
    const [dragging, setDragging] = useState(false);
    const uploadRef = useRef();

    useEffect(() => {
        uploadRef?.current?.addEventListener('change', async (e) => {
            const data = e.target.files;
            if (data.length === 0)
                return;

            const file = data[0];

            await uploadFileRaw(file);
        }, false);

        return uploadRef?.current?.removeEventListener('change', (e) => {
                console.log(e)
            }, false
        );
    }, []);

    const resizeFile = (file) => {
        console.log(file)
        if (resize.quality === 0) {
            return new Promise((resolve) => {
                resolve(file);
            })
        }

        return new Promise((resolve) => {
            const splitElement = file.type.split("/")[1];
            Resizer.imageFileResizer(
                file,
                resize.maxWidth,
                resize.maxHeight,
                splitElement.toUpperCase(),
                resize.quality,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });
    }

    const uploadFileRaw = async (file) => {
        const uploadUrl = (await getFileUploadUrl(file)).data.url;
        const resizedFile = await resizeFile(file)

        uploadFile(resizedFile, uploadUrl)
            .then(data => {
                const url = new URL(uploadUrl);
                url.search = "";
                onFileUploaded(url.toString());
            });
    }

    const getFileUploadUrl = async (file) => {
        return getUploadUrl({
            mimeType: file.type,
            type: type
        });
    }

    const uploadFile = async (file, url) => {
        return uploadfile(file, url);
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const droppedFiles = Array.from(e.dataTransfer.files);

        const file = droppedFiles[0];

        uploadFileRaw(file).then(r => {
            setDragging(false);
        });

    };


    return <>
        <div style={{cursor: "pointer", border: "2px dotted grey", padding: '5px'}}
             onClick={() => {
                 uploadRef?.current?.click();}}
             onDragEnter={handleDragEnter}
             onDragOver={handleDragOver}
             onDragLeave={handleDragLeave}
             onDrop={handleDrop}
        >
            {dragging? <div><Spin/></div>: children}
        </div>
        <form style={{display: "none"}}>
            <input ref={uploadRef} name={'file'} type={"file"}>
            </input>
        </form>
    </>

}

export default StorageDragger;