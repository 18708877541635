import {useEffect} from "react";

const CatalogueButton = ({
                             text,
    onClick = () => {},
                         }) => {
    return <button style={{
        borderRadius: 15,
        fontSize: 18,
        padding: '15px 25px',
        backgroundColor: 'black',
        color: 'white',
        cursor: 'pointer'
    }} onClick={onClick}>
        {text.toUpperCase()}
    </button>
}

export const CatalogueModal = ({
                                   title = "M-PACK",
                                   description = "This is a\nmultiline\ntext example",
                                   img = 'https://pbs.twimg.com/profile_images/1605297940242669568/q8-vPggS_400x400.jpg',
                                   hasVideo = false,
                                   onClick = () => {
                                   },
                                   onVideoClick = () => {
                                   },
                               }) => {

    useEffect(() => {
        // run this command when value changes
        document.getElementById("description").innerHTML = description
    }, [description])

    return <>
        <div style={{display: 'flex', padding: '20px 0px'}}>
            <img src={img} style={{width: '400px', height: '400px', objectFit: 'contain'}}/>
            <div style={{display: 'flex', flexDirection: 'column', padding: '0 20px', width: '100%', overflow: 'hidden'}}>
                <b><span style={{lineHeight: '40px', fontSize: '40px'}}>{title.toUpperCase()}</span></b>
                <p id='description'
                          style={{
                              whiteSpace: 'pre-line',
                              border: 'none',
                              width: '100%',
                              resize: 'none',
                              outline: 'none',
                              fontSize: '15px',
                              fontFamily: 'Gotham'
                          }}/>
                <div style={{display: 'flex', flexDirection: 'row', gap: '20px', padding: '20px 0 0 0'}}>
                    <CatalogueButton text={'click here'} onClick={onClick}/>
                    {hasVideo && <CatalogueButton text={'play video'} onClick={onVideoClick}/>}
                </div>
            </div>

        </div>
    </>
}
