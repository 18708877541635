import styled, {keyframes} from "styled-components";
import React from "react";

const MAX_LAYERS = 2;

const pulse = keyframes`
  0%,10% {
    opacity: 0;
    transform: scale(1.1);
  }
  80% {
    opacity: 0.7;
    transform: scale(1.15,1.15);
  }
  81%, 100% {
    opacity: 0;
    transform: scale(1);
  }
`;

const Scale = keyframes`
  0% {
    transform: scale(1);
  }
  35%, 80% {
    transform: scale(1.1,1.1);
  }
  100% {
    transform: scale(1);
  }
`;


const Pulse = styled.img`
  animation: ${({ layer }) => (layer ? pulse : Scale)} 1.5s infinite;
  height: ${({ layer = 0, height }) => height + layer * 8}px;
  position: absolute;
  width: ${({ layer = 0, width }) => width + layer * 8}px;
  z-index: ${({ layer = 0 }) => MAX_LAYERS - layer};
  object-fit: contain;
  opacity: ${({opacity}) => opacity};
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px;
  position: relative;
  &:hover ${Pulse} {
    animation: none;
    cursor: pointer;
  }
  &:hover {
    .big {
    display: none;
  }
  }
`;

const PulsatingImage = ({src, size, onClick, opacity = 1}) => {
    return <Wrapper onClick={onClick}>
        {Array.from(Array(MAX_LAYERS).keys()).map((key) => (
                <Pulse key={key} layer={key} src={src} height={size} width={size} opacity={opacity} className={key ? 'big' : 'small'}/>
            ))}
    </Wrapper>
}

export default PulsatingImage;