import {Button} from "antd";
import React from "react";
import {Marker} from "../../../services/marker-service";
import {ImageCard} from "../../../components/image-card";
import {MarkerConstant} from "../../../constant/marker-constant";

const MarkerMenu = (
    {
        markers,
        markerSelected = (_) => {},
        markerEdited = (_) => {},
        markerDeleted = (_) => {},
        markerAdded = () => {}
    }
        :
        { markers: Marker[], markerSelected?: (marker: Marker) => void, markerEdited?: (marker: Marker) => void, markerDeleted?: (marker: Marker) => void, markerAdded?: () => void,}
) => {

    const createCard = () => {
       return <>
           {markers.map(value => {
               return (
                   <ImageCard key={value.id} name={value.name}
                              // @ts-ignore
                              url={MarkerConstant[value.data.type]?.url?? ''}
                              onDelete={() => markerDeleted(value)}
                              onEdit={() => markerEdited(value)}
                              onSelect={() => markerSelected(value)}
                              imageStyle={{height: '100px', objectFit: 'contain'}}
                   />
               )
           })}
       </>
    }

    return (
    <div style={{display: 'flex', flexDirection: 'column', padding: '15px 15px', height: '90vh', overflowY: 'auto'}}>
        <div>
            {createCard()}
        </div>
        <Button onClick={() => {
            markerAdded();
        }}>
            Add new marker
        </Button>
    </div>
    );
}

export default MarkerMenu;