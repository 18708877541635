import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Editor from "./pages/editor/editor";
import Test from "./pages/test";
import Tour from "./pages/tour/tour";
import {TourMenu} from "./pages/tourMenu";
import {LocationMenu} from "./pages/locationMenu";

const router = createBrowserRouter([
    {
        path: '/',
        element: <TourMenu/>
    },
    {
        path: '/tour/:id',

        element: <LocationMenu/>
    },
    {
        path: '/editor',
        element: <Editor/>
    },
    {
        path: '/test',
        element: <Test/>
    },
    {
        path: '/tour',
        element: <Tour/>
    }

])


class ErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, errorInfo:any) {
        // Handle the error, log it, or perform any necessary actions
        console.error('Uncaught error:', error);
        console.error('Error info:', errorInfo);
        this.setState({ hasError: true });
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            // You can render a custom error message here
            return <div>Something went wrong.</div>;
        }
        // @ts-ignore
        return this.props.children;
    }
}


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ErrorBoundary>
        <RouterProvider router={router}/>
        <link rel="stylesheet"
              href="https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css"/>
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
