import {Button, Card} from "antd";
import React, {useEffect, useState} from "react";
import ListSort from "../../../components/list-sort";
import {arrayEquals} from "../../../util/array.util";
import {ImageCard} from "../../../components/image-card";

const TourMenu = ({
                      tours = [],
                      tourSelected = (tour) => {
                      },
                      tourAdded = () => {
                      },
                      tourEdited = (tour) => {
                      },
                      tourDeleted = (tour) => {
                      },
                      tourSorted = (sort) => {
                      }
                  }
) => {
    const [sortState, setSortState] = useState('normal')
    const [sort, setSort] = useState(tours.map(x => x.id));
    const cardDisable = sortState === 'sort';

    const createCard = () => {
        return tours.map(tour =>
            <ImageCard key={tour.id} name={tour.name} url={tour.url}
                       onSelect={() => {
                           if (cardDisable) return;
                           tourSelected(tour);
                       }}
                       onEdit={() => {
                           if (cardDisable) return;
                           tourEdited(tour);
                       }}
                       onDelete={() => {
                           if (cardDisable) return;
                           tourDeleted(tour);
                       }}
            />)
    }

    const handleSort = () => {
        const map = tours.map(x => x.id);
        if (!arrayEquals(map, sort)) {
            console.log(map);
            console.log(sort);
            console.log('sorted');
            tourSorted(sort);
        }
    };

    function switchSortState() {
        if (sortState === 'normal') {
            setSortState('sort');
        } else {
            handleSort();
            setSortState('normal');
        }
    }


    return (
        <div id='scrollableDiv' style={{display: 'flex', flexDirection: 'column', padding: '15px 15px', height: '90vh', overflowY: 'auto'}}>
            <div>
                <ListSort disabled={!cardDisable} onChange={(sorted: any[]) => {
                    const list = sorted.map(tour => tour.key);
                    setSort(list)
                }}>
                    {createCard()}
                </ListSort>
            </div>

            <Button onClick={() => {
                switchSortState();
            }}>
                {
                    sortState === 'sort' ? 'Confirm' : 'Sort'
                }
            </Button>
            <Button onClick={() => {
                if (cardDisable) return;
                tourAdded();
            }}>
                Add new location
            </Button>
        </div>
    );
}

export default TourMenu;