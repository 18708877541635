import React, {forwardRef, Suspense, useEffect, useRef, useState} from 'react'
import {Canvas, extend, useFrame, useThree, useLoader} from 'react-three-fiber'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {
    PerspectiveCamera,
    SphereGeometry,
    TextureLoader,
    BoxGeometry,
    RepeatWrapping,
    PlaneGeometry, DoubleSide, sRGBEncoding, SRGBColorSpace
} from "three";
import {Html} from '@react-three/drei';
import {useSpring} from "react-spring";
import Pulsating from "../../../components/button/pulsating";
import env from "react-dotenv";
import CameraAnimation from "../../../components/three/camera-animation";
import PulsatingImage from "../../../components/button/pulsating-image";
import {MarkerConstant} from "../../../constant/marker-constant";

extend({OrbitControls, SphereGeometry, PerspectiveCamera, BoxGeometry, PlaneGeometry})

function Dome({sceneRef, img, opacity}) {
    const texture = useLoader(TextureLoader, img);
    texture.wrapS = RepeatWrapping;
    texture.repeat.x = -1;
    return (
        <mesh ref={sceneRef}>
            <sphereGeometry attach="geometry" args={[1000, 60, 40]}/>
            <meshBasicMaterial attach="material" map={texture} side={DoubleSide} transparent/>
        </mesh>
    )
}

function RouterButton({position, onClick, size = 40, src}) {
    return (
        <Html center position={position} zIndexRange={[0, 100]}>
            <PulsatingImage src={src} size={size} onClick={onClick} opacity={0.6}/>
        </Html>
    )
}

function CatalogueButton({position, onClick, size = 40, src}) {
    return (
        <Html center position={position} zIndexRange={[0, 100]}>
            <PulsatingImage src={src} size={size} onClick={onClick}/>
        </Html>
    )
}

function VideoButton({position, onClick, size = 40, src}) {
    return (
        <Html center position={position} zIndexRange={[0, 100]}>
            <PulsatingImage src={src} size={size} onClick={onClick}/>
        </Html>
    )
}

const SceneContent = forwardRef(({
                                     url, markers = [], buttonDisabled = true, selectPosition = false,
                                     autoRotateSpeed = 0.2,
                                     onPositionSelected = (position) => {
                                     },
                                     onRouterClick = (marker) => {
                                     },
                                     onCatalogueClick = (marker) => {
                                     },
                                     onVideoClick = (marker) => {
                                     },
                                     defaultRotate = true,
                                     backToHorizon = true,
                                     ...props
                                 }, ref) => {
    React.useImperativeHandle(ref, () => ({
        setMovingState
    }));
    const resetPosition = [0, 0, 0.01];
    const orbitControlsRef = useRef();
    const sceneRef = useRef();
    const cameraRef = useRef();
    const animationRef = useRef();
    const [opacity, setOpacity] = useState()

    const [spring, api] = useSpring(() => {
    });

    const setMovingState = (movingState, timeout = 0) => {
        setTimeout(() => {
            animationRef.current?.setMovingState(movingState);
        }, timeout);
    };

    const dissolve = (onZero = () => {
    }) => {
        api.start({
            from: {
                opacity: 1,
            },
            to: {
                opacity: 0,
            },
            config: {
                duration: 3000,
                tension: 180,
                friction: 12
            },
            onChange: ({value}) => {
                const {opacity} = value;
                setOpacity(opacity);
            },
            onRest: () => {
                onZero();
                show();
            }

        });
    }

    const show = () => {
        api.start({
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
            config: {
                duration: 3000,
                tension: 180,
                friction: 12
            },
            onChange: ({value}) => {
                const {opacity} = value;
                setOpacity(opacity)
            },

        });
    }

    const routerClicked = (value) => {
        if (buttonDisabled) return;
        if (animationRef.current) {
            animationRef.current.moveCameraTo(value.data.position);
        }

        dissolve(
            () => onRouterClick(value)
        )
    }

    const createMarker = () => {
        return markers.map(value => {
            if (value.data.type === 'Router')
                return <RouterButton key={value.id}
                                     position={value.data.position}
                                     src={MarkerConstant.Router.sceneUrl}
                                     onClick={() => {
                                         routerClicked(value)
                                     }}/>

            if (value.data.type === 'Catalogue')
                return <CatalogueButton key={value.id}
                                        position={value.data.position}
                                        src={MarkerConstant.Catalogue.sceneUrl}
                                        onClick={() => {
                                            if (buttonDisabled) return;
                                            onCatalogueClick(value);
                                        }}/>

            if (value.data.type === 'Video')
                return <VideoButton key={value.id}
                                        position={value.data.position}
                                        src={MarkerConstant.Video.sceneUrl}
                                        onClick={() => {
                                            if (buttonDisabled) return;
                                            onVideoClick(value);
                                        }}
                />

            return <></>
        })
    }


    return url ?
    <>
        <Canvas camera={{position: resetPosition}} style={{opacity: opacity}}  flat>
            <CameraAnimation ref={animationRef} orbitControls={orbitControlsRef} camera={cameraRef} sceneRef={sceneRef}
                             defaultRotate={defaultRotate}
                             backToHorizon={backToHorizon}
                             selectPosition={selectPosition} onPositionSelected={onPositionSelected}
                             maxPolarAngle={Math.PI * 0.8}
                             minPolarAngle={Math.PI * 0.4}
                             resetPosition={resetPosition}
                             enableZoom={false} enablePan={false} enableDamping dampingFactor={0.2}
                             autoRotate autoRotateSpeed={autoRotateSpeed}/>
            <Suspense fallback={null}>
                <Dome sceneRef={sceneRef} img={url}/>
            </Suspense>
            {createMarker()}
            <ambientLight intensity={0}/>
        </Canvas>
    </> :
        <></>
});

export default SceneContent;