import React, {useEffect, useState} from "react";
import {InboxOutlined} from '@ant-design/icons';
import {Form, FormInstance, Input, Modal, Upload} from "antd";
import env from "react-dotenv";
import {Scene, SceneCreate} from "../../../services/scene-service";
import TextArea from "antd/lib/input/TextArea";
import StorageDragger from "../../../components/upload/storage-dragger";

const {Dragger} = Upload;

const SceneModal = (
    {
        open,
        locationId,
        scene,
        onCreate = (_) => {
        },
        onCancel = () => {
        }
    }
        :
        {
            open: boolean,
            locationId?: string,
            scene?: Scene,
            onCreate: (scene: SceneCreate) => void,
            onCancel: () => void
        }) => {

    const formRef = React.useRef<FormInstance>(null);

    const [file, setFile] = useState<string>();
    const [locationData, setSceneData] = useState<SceneCreate>();

    useEffect(() => {
        if (scene) {
            setFile(scene.url);
            setSceneData(scene);
            formRef.current?.setFieldsValue(
                {
                    ...scene
                }
            )
        }
    }, [scene, locationId]);



    const switchUploadContent = () => {
        return (file) ?
            <img alt={'img'} src={file} style={{ objectFit: 'contain', width: '100%'}}/>
            :
            <>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

            </>
            ;
    }

    return <Modal open={open}
                  onOk={() => {
                      formRef.current?.validateFields().then(() => {
                          onCreate({
                              name: formRef.current?.getFieldValue('name'),
                              id: locationData?.id,
                              url: file?? '',
                              locationId: locationId?? '',
                          });
                      })
                  }}
                  onCancel={() => {
                      onCancel();
                  }}

    >
        <StorageDragger
            type={'scene'}
            onFileUploaded={(src) => {
                setFile(src);}}
        >
            {switchUploadContent()}
        </StorageDragger>
        <Form style={{padding: '20px 0'}}
              ref={formRef}
        >
            <Form.Item name='name' label='Name' rules={[{required: true}]}>
                <TextArea rows={2} autoSize={false} style={{resize: 'none'}}/>
            </Form.Item>
        </Form>


    </Modal>
}

export default SceneModal;